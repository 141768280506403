import { ICountry } from '@rainhq/models';

export const COUNTRIES: ICountry[] = [
  {
    alpha2: 'BH',
    alpha3: 'BHR',
    names: {
      ar: 'البحرين',
      en: 'Bahrain',
      tr: 'Bahreyn',
      ur: 'بحرین',
    },
    phoneCountryCode: '973',
    supported: 'supported',
  },
  {
    alpha2: 'KW',
    alpha3: 'KWT',
    names: {
      ar: 'الكويت',
      en: 'Kuwait',
      tr: 'Kuveyt',
      ur: 'کویت',
    },
    phoneCountryCode: '965',
    supported: 'supported',
  },
  {
    alpha2: 'OM',
    alpha3: 'OMN',
    names: {
      ar: 'سلطنة عمان',
      en: 'Oman',
      tr: 'Umman',
      ur: 'عمان',
    },
    phoneCountryCode: '968',
    supported: 'supported',
  },
  {
    alpha2: 'QA',
    alpha3: 'QAT',
    names: {
      ar: 'دولة قطر',
      en: 'Qatar',
      tr: 'Katar',
      ur: 'قطر',
    },
    phoneCountryCode: '974',
    supported: 'supported',
  },
  {
    alpha2: 'SA',
    alpha3: 'SAU',
    names: {
      ar: 'المملكة العربية السعودية',
      en: 'Saudi Arabia',
      tr: 'Suudi Arabistan',
      ur: 'سعودی عرب',
    },
    phoneCountryCode: '966',
    supported: 'supported',
  },
  {
    alpha2: 'TR',
    alpha3: 'TUR',
    names: {
      ar: 'تركيا',
      en: 'Turkey',
      tr: 'Türkiye',
      ur: 'ترکی',
    },
    phoneCountryCode: '90',
    supported: 'supported',
  },
  {
    alpha2: 'AE',
    alpha3: 'ARE',
    names: {
      ar: 'الإمارات العربية المتحدة',
      en: 'United Arab Emirates',
      tr: 'Birleşik Arap Emirlikleri',
      ur: 'متحدہ عرب امارات',
    },
    phoneCountryCode: '971',
    supported: 'supported',
  },
  {
    alpha2: 'AF',
    alpha3: 'AFG',
    names: {
      ar: 'أفغانستان',
      en: 'Afghanistan',
      tr: 'Afganistan',
      ur: 'افغانستان',
    },
    phoneCountryCode: '93',
    supported: 'supported',
  },
  {
    alpha2: 'AL',
    alpha3: 'ALB',
    names: {
      ar: 'ألبانيا',
      en: 'Albania',
      tr: 'Arnavutluk',
      ur: 'البانیہ',
    },
    phoneCountryCode: '355',
    supported: 'supported',
  },
  {
    alpha2: 'DZ',
    alpha3: 'DZA',
    names: {
      ar: 'الجزائر',
      en: 'Algeria',
      tr: 'Cezayir',
      ur: 'الجزائر',
    },
    phoneCountryCode: '213',
    supported: 'supported',
  },
  {
    alpha2: 'AS',
    alpha3: 'ASM',
    names: {
      ar: 'ساموا الأمريكية',
      en: 'American Samoa',
      tr: 'Amerikan Samoası',
      ur: 'امریکی سمووا',
    },
    phoneCountryCode: '1684',
    supported: 'supported',
  },
  {
    alpha2: 'AD',
    alpha3: 'AND',
    names: {
      ar: 'أندورا',
      en: 'Andorra',
      tr: 'Andorra',
      ur: 'انڈورا',
    },
    phoneCountryCode: '376',
    supported: 'supported',
  },
  {
    alpha2: 'AO',
    alpha3: 'AGO',
    names: {
      ar: 'أنغولا',
      en: 'Angola',
      tr: 'Angola',
      ur: 'انگولہ',
    },
    phoneCountryCode: '244',
    supported: 'supported',
  },
  {
    alpha2: 'AI',
    alpha3: 'AIA',
    names: {
      ar: 'أنغيلا',
      en: 'Anguilla',
      tr: 'Anguilla',
      ur: 'اینگویلا',
    },
    phoneCountryCode: '1264',
    supported: 'supported',
  },
  {
    alpha2: 'AG',
    alpha3: 'ATG',
    names: {
      ar: 'أنتيغوا وبربودا',
      en: 'Antigua and Barbuda',
      tr: 'Antigua ve Barbuda',
      ur: 'اینٹیگوا و باربوڈا',
    },
    phoneCountryCode: '1268',
    supported: 'supported',
  },
  {
    alpha2: 'AR',
    alpha3: 'ARG',
    names: {
      ar: 'الأرجنتين',
      en: 'Argentina',
      tr: 'Arjantin',
      ur: 'ارجنٹائن',
    },
    phoneCountryCode: '54',
    supported: 'supported',
  },
  {
    alpha2: 'AM',
    alpha3: 'ARM',
    names: {
      ar: 'أرمينيا',
      en: 'Armenia',
      tr: 'Ermenistan',
      ur: 'آرمینیا',
    },
    phoneCountryCode: '374',
    supported: 'supported',
  },
  {
    alpha2: 'AW',
    alpha3: 'ABW',
    names: {
      ar: 'أروبا',
      en: 'Aruba',
      tr: 'Aruba',
      ur: 'اروبا',
    },
    phoneCountryCode: '297',
    supported: 'supported',
  },
  {
    alpha2: 'AU',
    alpha3: 'AUS',
    names: {
      ar: 'أستراليا',
      en: 'Australia',
      tr: 'Avustralya',
      ur: 'آسٹریلیا',
    },
    phoneCountryCode: '61',
    supported: 'supported',
  },
  {
    alpha2: 'AT',
    alpha3: 'AUT',
    names: {
      ar: 'النمسا',
      en: 'Austria',
      tr: 'Avusturya',
      ur: 'آسٹریا',
    },
    phoneCountryCode: '43',
    supported: 'supported',
  },
  {
    alpha2: 'AZ',
    alpha3: 'AZE',
    names: {
      ar: 'أذربيجان',
      en: 'Azerbaijan',
      tr: 'Azerbaycan',
      ur: 'آذربائیجان',
    },
    phoneCountryCode: '994',
    supported: 'supported',
  },
  {
    alpha2: 'BS',
    alpha3: 'BHS',
    names: {
      ar: 'الباهاما',
      en: 'Bahamas',
      tr: 'Bahamalar',
      ur: 'بہاماس',
    },
    phoneCountryCode: '1242',
    supported: 'supported',
  },
  {
    alpha2: 'BD',
    alpha3: 'BGD',
    names: {
      ar: 'بنغلاديش',
      en: 'Bangladesh',
      tr: 'Bangladeş',
      ur: 'بنگلہ دیش',
    },
    phoneCountryCode: '880',
    supported: 'supported',
  },
  {
    alpha2: 'BB',
    alpha3: 'BRB',
    names: {
      ar: 'بربادوس',
      en: 'Barbados',
      tr: 'Barbados',
      ur: 'بارباڈوس',
    },
    phoneCountryCode: '1246',
    supported: 'supported',
  },
  {
    alpha2: 'BY',
    alpha3: 'BLR',
    names: {
      ar: 'روسيا البيضاء',
      en: 'Belarus',
      tr: 'Beyaz Rusya',
      ur: 'بیلاروس',
    },
    phoneCountryCode: '375',
    supported: 'supported',
  },
  {
    alpha2: 'BE',
    alpha3: 'BEL',
    names: {
      ar: 'بلجيكا',
      en: 'Belgium',
      tr: 'Belçika',
      ur: 'بلجئیم',
    },
    phoneCountryCode: '32',
    supported: 'supported',
  },
  {
    alpha2: 'BZ',
    alpha3: 'BLZ',
    names: {
      ar: 'بليز',
      en: 'Belize',
      tr: 'Belize',
      ur: 'بیلیز',
    },
    phoneCountryCode: '501',
    supported: 'supported',
  },
  {
    alpha2: 'BJ',
    alpha3: 'BEN',
    names: {
      ar: 'بنين',
      en: 'Benin',
      tr: 'Benin',
      ur: 'بینن',
    },
    phoneCountryCode: '229',
    supported: 'supported',
  },
  {
    alpha2: 'BM',
    alpha3: 'BMU',
    names: {
      ar: 'برمودا',
      en: 'Bermuda',
      tr: 'Bermuda',
      ur: 'برمودا',
    },
    phoneCountryCode: '1441',
    supported: 'supported',
  },
  {
    alpha2: 'BT',
    alpha3: 'BTN',
    names: {
      ar: 'بوتان',
      en: 'Bhutan',
      tr: 'Bhutan',
      ur: 'بھوٹان',
    },
    phoneCountryCode: '975',
    supported: 'supported',
  },
  {
    alpha2: 'BO',
    alpha3: 'BOL',
    names: {
      ar: 'بوليفيا',
      en: 'Bolivia',
      tr: 'Bolivya',
      ur: 'بولیویا',
    },
    phoneCountryCode: '591',
    supported: 'supported',
  },
  {
    alpha2: 'BA',
    alpha3: 'BIH',
    names: {
      ar: 'البوسنة والهرسك',
      en: 'Bosnia and Herzegovina',
      tr: 'Bosna-Hersek',
      ur: 'بوسنیا و ہرزیگووینا',
    },
    phoneCountryCode: '387',
    supported: 'supported',
  },
  {
    alpha2: 'BW',
    alpha3: 'BWA',
    names: {
      ar: 'بوتسوانا',
      en: 'Botswana',
      tr: 'Botsvana',
      ur: 'بوٹسوانا',
    },
    phoneCountryCode: '267',
    supported: 'supported',
  },
  {
    alpha2: 'BR',
    alpha3: 'BRA',
    names: {
      ar: 'البرازيل',
      en: 'Brazil',
      tr: 'Brezilya',
      ur: 'برازیل',
    },
    phoneCountryCode: '55',
    supported: 'supported',
  },
  {
    alpha2: 'IO',
    alpha3: 'IOT',
    names: {
      ar: 'إقليم المحيط البريطاني الهندي',
      en: 'British Indian Ocean Territory',
      tr: 'İngiliz Hint Okyanusu Toprakları',
      ur: 'برطانوی بحرہند خطہ',
    },
    phoneCountryCode: '246',
    supported: 'supported',
  },
  {
    alpha2: 'VG',
    alpha3: 'VGB',
    names: {
      ar: 'جزر فيرجن البريطانية',
      en: 'British Virgin Islands',
      tr: 'İngiliz Virgin Adaları',
      ur: 'برطانوی جزائر ورجن',
    },
    phoneCountryCode: '1284',
    supported: 'supported',
  },
  {
    alpha2: 'BN',
    alpha3: 'BRN',
    names: {
      ar: 'بروناي',
      en: 'Brunei Darussalam',
      tr: 'Brunei',
      ur: 'برونائی',
    },
    phoneCountryCode: '673',
    supported: 'supported',
  },
  {
    alpha2: 'BG',
    alpha3: 'BGR',
    names: {
      ar: 'بلغاريا',
      en: 'Bulgaria',
      tr: 'Bulgaristan',
      ur: 'بلغاریہ',
    },
    phoneCountryCode: '359',
    supported: 'supported',
  },
  {
    alpha2: 'BF',
    alpha3: 'BFA',
    names: {
      ar: 'بوركينا فاسو',
      en: 'Burkina Faso',
      tr: 'Burkina Faso',
      ur: 'برکینا فاسو',
    },
    phoneCountryCode: '226',
    supported: 'supported',
  },
  {
    alpha2: 'BI',
    alpha3: 'BDI',
    names: {
      ar: 'بوروندي',
      en: 'Burundi',
      tr: 'Burundi',
      ur: 'برونڈی',
    },
    phoneCountryCode: '257',
    supported: 'supported',
  },
  {
    alpha2: 'KH',
    alpha3: 'KHM',
    names: {
      ar: 'كمبوديا',
      en: 'Cambodia',
      tr: 'Kamboçya',
      ur: 'کمبوڈیا',
    },
    phoneCountryCode: '855',
    supported: 'supported',
  },
  {
    alpha2: 'CM',
    alpha3: 'CMR',
    names: {
      ar: 'الكاميرون',
      en: 'Cameroon',
      tr: 'Kamerun',
      ur: 'کیمرون',
    },
    phoneCountryCode: '237',
    supported: 'supported',
  },
  {
    alpha2: 'CA',
    alpha3: 'CAN',
    names: {
      ar: 'كندا',
      en: 'Canada',
      tr: 'Kanada',
      ur: 'کینیڈا',
    },
    phoneCountryCode: '1',
    supported: 'supported',
  },
  {
    alpha2: 'CV',
    alpha3: 'CPV',
    names: {
      ar: 'الرأس الأخضر',
      en: 'Cape Verde',
      tr: 'Yeşil Burun',
      ur: 'کیپ وردے',
    },
    phoneCountryCode: '238',
    supported: 'supported',
  },
  {
    alpha2: 'KY',
    alpha3: 'CYM',
    names: {
      ar: 'جزر كايمان',
      en: 'Cayman Islands',
      tr: 'Cayman Adaları',
      ur: 'جزائر کیمن',
    },
    phoneCountryCode: '1345',
    supported: 'supported',
  },
  {
    alpha2: 'CF',
    alpha3: 'CAF',
    names: {
      ar: 'جمهورية افريقيا الوسطى',
      en: 'Central African Republic',
      tr: 'Orta Afrika Cumhuriyeti',
      ur: 'وسطی افریقی جمہوریہ',
    },
    phoneCountryCode: '236',
    supported: 'supported',
  },
  {
    alpha2: 'TD',
    alpha3: 'TCD',
    names: {
      ar: 'تشاد',
      en: 'Chad',
      tr: 'Çad',
      ur: 'چاڈ',
    },
    phoneCountryCode: '235',
    supported: 'supported',
  },
  {
    alpha2: 'CL',
    alpha3: 'CHL',
    names: {
      ar: 'تشيلي',
      en: 'Chile',
      tr: 'Şili',
      ur: 'چلی',
    },
    phoneCountryCode: '56',
    supported: 'supported',
  },
  {
    alpha2: 'CN',
    alpha3: 'CHN',
    names: {
      ar: 'الصين',
      en: 'China',
      tr: 'Çin',
      ur: 'چین',
    },
    phoneCountryCode: '86',
    supported: 'supported',
  },
  {
    alpha2: 'CX',
    alpha3: 'CXR',
    names: {
      ar: 'جزيرة الكريسماس',
      en: 'Christmas Island',
      tr: 'Christmas Adası',
      ur: 'جزیرہ کرسمس',
    },
    phoneCountryCode: '61',
    supported: 'supported',
  },
  {
    alpha2: 'CC',
    alpha3: 'CCK',
    names: {
      ar: 'جزر كوكوس',
      en: 'Cocos Islands',
      tr: 'Cocos Adaları',
      ur: 'کوکوس جزائر',
    },
    phoneCountryCode: '61',
    supported: 'supported',
  },
  {
    alpha2: 'CO',
    alpha3: 'COL',
    names: {
      ar: 'كولومبيا',
      en: 'Colombia',
      tr: 'Kolombiya',
      ur: 'کولمبیا',
    },
    phoneCountryCode: '57',
    supported: 'supported',
  },
  {
    alpha2: 'KM',
    alpha3: 'COM',
    names: {
      ar: 'جزر القمر',
      en: 'Comoros',
      tr: 'Komor Adaları',
      ur: 'کوموروس',
    },
    phoneCountryCode: '269',
    supported: 'supported',
  },
  {
    alpha2: 'CD',
    alpha3: 'COD',
    names: {
      ar: 'جمهورية الكونغو الديموقراطية',
      en: 'Congo, the Democratic Republic',
      tr: 'Demokratik Kongo Cumhuriyeti',
      ur: 'جمہوری جمہوریہ کانگ',
    },
    phoneCountryCode: '243',
    supported: 'supported',
  },
  {
    alpha2: 'CK',
    alpha3: 'COK',
    names: {
      ar: 'جزر كوك',
      en: 'Cook Islands',
      tr: 'Cook Adaları',
      ur: 'جزائر کک',
    },
    phoneCountryCode: '682',
    supported: 'supported',
  },
  {
    alpha2: 'CR',
    alpha3: 'CRI',
    names: {
      ar: 'كوستا ريكا',
      en: 'Costa Rica',
      tr: 'Kosta Rika',
      ur: 'کوسٹا ریکا',
    },
    phoneCountryCode: '506',
    supported: 'supported',
  },
  {
    alpha2: 'HR',
    alpha3: 'HRV',
    names: {
      ar: 'كرواتيا',
      en: 'Croatia',
      tr: 'Hırvatistan',
      ur: 'کروشیا',
    },
    phoneCountryCode: '385',
    supported: 'supported',
  },
  {
    alpha2: 'CU',
    alpha3: 'CUB',
    names: {
      ar: 'كوبا',
      en: 'Cuba',
      tr: 'Küba',
      ur: 'کیوبا',
    },
    phoneCountryCode: '53',
    supported: 'supported',
  },
  {
    alpha2: 'CW',
    alpha3: 'CUW',
    names: {
      ar: 'كوراكاو',
      en: 'Curacao',
      tr: 'Curacao',
      ur: 'کوراکاؤ',
    },
    phoneCountryCode: '599',
    supported: 'supported',
  },
  {
    alpha2: 'CY',
    alpha3: 'CYP',
    names: {
      ar: 'قبرص',
      en: 'Cyprus',
      tr: 'Kıbrıs',
      ur: 'سائپرس',
    },
    phoneCountryCode: '357',
    supported: 'supported',
  },
  {
    alpha2: 'CZ',
    alpha3: 'CZE',
    names: {
      ar: 'جمهورية التشيك',
      en: 'Czech Republic',
      tr: 'Çek Cumhuriyeti',
      ur: 'چیک جمہوریہ',
    },
    phoneCountryCode: '420',
    supported: 'supported',
  },
  {
    alpha2: 'DK',
    alpha3: 'DNK',
    names: {
      ar: 'الدنمارك',
      en: 'Denmark',
      tr: 'Danimarka',
      ur: 'ڈنمارک',
    },
    phoneCountryCode: '45',
    supported: 'supported',
  },
  {
    alpha2: 'DJ',
    alpha3: 'DJI',
    names: {
      ar: 'جيبوتي',
      en: 'Djibouti',
      tr: 'Cibuti',
      ur: 'جبوتی',
    },
    phoneCountryCode: '253',
    supported: 'supported',
  },
  {
    alpha2: 'DM',
    alpha3: 'DMA',
    names: {
      ar: 'دومينيكا',
      en: 'Dominica',
      tr: 'Dominika',
      ur: 'ڈومینیکا',
    },
    phoneCountryCode: '1767',
    supported: 'supported',
  },
  {
    alpha2: 'DO',
    alpha3: 'DOM',
    names: {
      ar: 'جمهورية الدومنيكان',
      en: 'Dominican Republic',
      tr: 'Dominik Cumhuriyeti',
      ur: 'ڈومینیکن ریپبلک',
    },
    phoneCountryCode: '1',
    supported: 'supported',
  },
  {
    alpha2: 'TL',
    alpha3: 'TLS',
    names: {
      ar: 'تيمور الشرقية',
      en: 'East Timor',
      tr: 'Doğu Timor',
      ur: 'مشرقی تیمور',
    },
    phoneCountryCode: '670',
    supported: 'supported',
  },
  {
    alpha2: 'EC',
    alpha3: 'ECU',
    names: {
      ar: 'الإكوادور',
      en: 'Ecuador',
      tr: 'Ekvador',
      ur: 'ایکواڈور',
    },
    phoneCountryCode: '593',
    supported: 'supported',
  },
  {
    alpha2: 'EG',
    alpha3: 'EGY',
    names: {
      ar: 'مصر',
      en: 'Egypt',
      tr: 'Mısır',
      ur: 'مصر',
    },
    phoneCountryCode: '20',
    supported: 'supported',
  },
  {
    alpha2: 'SV',
    alpha3: 'SLV',
    names: {
      ar: 'السلفادور',
      en: 'El Salvador',
      tr: 'El Salvador',
      ur: 'ایل سیلواڈور',
    },
    phoneCountryCode: '503',
    supported: 'supported',
  },
  {
    alpha2: 'GQ',
    alpha3: 'GNQ',
    names: {
      ar: 'غينيا الإستوائية',
      en: 'Equatorial Guinea',
      tr: 'Ekvator Ginesi',
      ur: 'استوائی گنی',
    },
    phoneCountryCode: '240',
    supported: 'supported',
  },
  {
    alpha2: 'ER',
    alpha3: 'ERI',
    names: {
      ar: 'إريتريا',
      en: 'Eritrea',
      tr: 'Eritre',
      ur: 'اریٹیریا',
    },
    phoneCountryCode: '291',
    supported: 'supported',
  },
  {
    alpha2: 'EE',
    alpha3: 'EST',
    names: {
      ar: 'استونيا',
      en: 'Estonia',
      tr: 'Estonya',
      ur: 'ایسٹونیا',
    },
    phoneCountryCode: '372',
    supported: 'supported',
  },
  {
    alpha2: 'ET',
    alpha3: 'ETH',
    names: {
      ar: 'أثيوبيا',
      en: 'Ethiopia',
      tr: 'Etiyopya',
      ur: 'ایتھوپیا',
    },
    phoneCountryCode: '251',
    supported: 'supported',
  },
  {
    alpha2: 'FK',
    alpha3: 'FLK',
    names: {
      ar: 'جزر فوكلاند',
      en: 'Falkland Islands (Malvinas)',
      tr: 'Falkland Adaları',
      ur: 'فالک لینڈ جزیرے',
    },
    phoneCountryCode: '500',
    supported: 'supported',
  },
  {
    alpha2: 'FO',
    alpha3: 'FRO',
    names: {
      ar: 'جزر صناعية',
      en: 'Faroe Islands',
      tr: 'Faroe Adaları',
      ur: 'جزائر فارو',
    },
    phoneCountryCode: '298',
    supported: 'supported',
  },
  {
    alpha2: 'FJ',
    alpha3: 'FJI',
    names: {
      ar: 'فيجي',
      en: 'Fiji',
      tr: 'Fiji',
      ur: 'فجی',
    },
    phoneCountryCode: '679',
    supported: 'supported',
  },
  {
    alpha2: 'FI',
    alpha3: 'FIN',
    names: {
      ar: 'فنلندا',
      en: 'Finland',
      tr: 'Finlandiya',
      ur: 'فن لینڈ',
    },
    phoneCountryCode: '358',
    supported: 'supported',
  },
  {
    alpha2: 'FR',
    alpha3: 'FRA',
    names: {
      ar: 'فرنسا',
      en: 'France',
      tr: 'Fransa',
      ur: 'فرانس',
    },
    phoneCountryCode: '33',
    supported: 'supported',
  },
  {
    alpha2: 'GF',
    alpha3: 'GUF',
    names: {
      ar: 'غينيا الفرنسية',
      en: 'French Guiana',
      tr: 'Fransız Guyanası',
      ur: 'فرنچ گھانا',
    },
    phoneCountryCode: '594',
    supported: 'supported',
  },
  {
    alpha2: 'PF',
    alpha3: 'PYF',
    names: {
      ar: 'بولينيزيا الفرنسية',
      en: 'French Polynesia',
      tr: 'Fransız Polenezyası',
      ur: 'فرانسیسی پولینیشیا',
    },
    phoneCountryCode: '689',
    supported: 'supported',
  },
  {
    alpha2: 'GA',
    alpha3: 'GAB',
    names: {
      ar: 'الغابون',
      en: 'Gabon',
      tr: 'Gabon',
      ur: 'گیبون',
    },
    phoneCountryCode: '241',
    supported: 'supported',
  },
  {
    alpha2: 'GM',
    alpha3: 'GMB',
    names: {
      ar: 'غامبيا',
      en: 'Gambia',
      tr: 'Gambiya',
      ur: 'گیمبیا',
    },
    phoneCountryCode: '220',
    supported: 'supported',
  },
  {
    alpha2: 'GE',
    alpha3: 'GEO',
    names: {
      ar: 'جورجيا',
      en: 'Georgia',
      tr: 'Gürcistan',
      ur: 'جارجیا',
    },
    phoneCountryCode: '995',
    supported: 'supported',
  },
  {
    alpha2: 'DE',
    alpha3: 'DEU',
    names: {
      ar: 'ألمانيا',
      en: 'Germany',
      tr: 'Almanya',
      ur: 'جرمنی',
    },
    phoneCountryCode: '49',
    supported: 'supported',
  },
  {
    alpha2: 'GH',
    alpha3: 'GHA',
    names: {
      ar: 'غانا',
      en: 'Ghana',
      tr: 'Gana',
      ur: 'گھانا',
    },
    phoneCountryCode: '233',
    supported: 'supported',
  },
  {
    alpha2: 'GI',
    alpha3: 'GIB',
    names: {
      ar: 'جبل طارق',
      en: 'Gibraltar',
      tr: 'Cebelitarık',
      ur: 'جبرالٹر',
    },
    phoneCountryCode: '350',
    supported: 'supported',
  },
  {
    alpha2: 'GR',
    alpha3: 'GRC',
    names: {
      ar: 'اليونان',
      en: 'Greece',
      tr: 'Yunanistan',
      ur: 'یونان',
    },
    phoneCountryCode: '30',
    supported: 'supported',
  },
  {
    alpha2: 'GL',
    alpha3: 'GRL',
    names: {
      ar: 'الأرض الخضراء',
      en: 'Greenland',
      tr: 'Grönland',
      ur: 'گرین لینڈ',
    },
    phoneCountryCode: '299',
    supported: 'supported',
  },
  {
    alpha2: 'GD',
    alpha3: 'GRD',
    names: {
      ar: 'غرينادا',
      en: 'Grenada',
      tr: 'Grenada',
      ur: 'گریناڈا',
    },
    phoneCountryCode: '1473',
    supported: 'supported',
  },
  {
    alpha2: 'GP',
    alpha3: 'GLP',
    names: {
      ar: 'غوادلوب',
      en: 'Guadeloupe',
      tr: 'Guadelup',
      ur: 'گواڈیلوپ',
    },
    phoneCountryCode: '590',
    supported: 'supported',
  },
  {
    alpha2: 'GU',
    alpha3: 'GUM',
    names: {
      ar: 'غوام',
      en: 'Guam',
      tr: 'Guam',
      ur: 'گوام',
    },
    phoneCountryCode: '1671',
    supported: 'supported',
  },
  {
    alpha2: 'GT',
    alpha3: 'GTM',
    names: {
      ar: 'غواتيمالا',
      en: 'Guatemala',
      tr: 'Guatemala',
      ur: 'گواتیمالا',
    },
    phoneCountryCode: '502',
    supported: 'supported',
  },
  {
    alpha2: 'GG',
    alpha3: 'GGY',
    names: {
      ar: 'غيرنسي',
      en: 'Guernsey',
      tr: 'Guernsey',
      ur: 'گرنزی',
    },
    phoneCountryCode: '441481',
    supported: 'supported',
  },
  {
    alpha2: 'GN',
    alpha3: 'GIN',
    names: {
      ar: 'غينيا',
      en: 'Guinea',
      tr: 'Gine',
      ur: 'جمہوریہ گنی',
    },
    phoneCountryCode: '224',
    supported: 'supported',
  },
  {
    alpha2: 'GW',
    alpha3: 'GNB',
    names: {
      ar: 'غينيا بيساو',
      en: 'Guinea-Bissau',
      tr: 'Gine-Bissau',
      ur: 'گنی بساؤ',
    },
    phoneCountryCode: '245',
    supported: 'supported',
  },
  {
    alpha2: 'GY',
    alpha3: 'GUY',
    names: {
      ar: 'غيانا',
      en: 'Guyana',
      tr: 'Guyana',
      ur: 'گیانا',
    },
    phoneCountryCode: '592',
    supported: 'supported',
  },
  {
    alpha2: 'HT',
    alpha3: 'HTI',
    names: {
      ar: 'هايتي',
      en: 'Haiti',
      tr: 'Haiti',
      ur: 'ہیٹی',
    },
    phoneCountryCode: '509',
    supported: 'supported',
  },
  {
    alpha2: 'HN',
    alpha3: 'HND',
    names: {
      ar: 'هندوراس',
      en: 'Honduras',
      tr: 'Honduras',
      ur: 'ہونڈوراس',
    },
    phoneCountryCode: '504',
    supported: 'supported',
  },
  {
    alpha2: 'HK',
    alpha3: 'HKG',
    names: {
      ar: 'هونغ كونغ',
      en: 'Hong Kong',
      tr: 'Hong Kong',
      ur: 'ہانگ کانگ',
    },
    phoneCountryCode: '852',
    supported: 'supported',
  },
  {
    alpha2: 'HU',
    alpha3: 'HUN',
    names: {
      ar: 'هنغاريا',
      en: 'Hungary',
      tr: 'Macaristan',
      ur: 'ہنگری',
    },
    phoneCountryCode: '36',
    supported: 'supported',
  },
  {
    alpha2: 'IS',
    alpha3: 'ISL',
    names: {
      ar: 'أيسلندا',
      en: 'Iceland',
      tr: 'İzlanda',
      ur: 'آئس لینڈ',
    },
    phoneCountryCode: '354',
    supported: 'supported',
  },
  {
    alpha2: 'IN',
    alpha3: 'IND',
    names: {
      ar: 'الهند',
      en: 'India',
      tr: 'Hindistan',
      ur: 'انڈیا',
    },
    phoneCountryCode: '91',
    supported: 'supported',
  },
  {
    alpha2: 'ID',
    alpha3: 'IDN',
    names: {
      ar: 'أندونيسيا',
      en: 'Indonesia',
      tr: 'Endonezya',
      ur: 'انڈونیشیا',
    },
    phoneCountryCode: '62',
    supported: 'supported',
  },
  {
    alpha2: 'IR',
    alpha3: 'IRN',
    names: {
      ar: 'إيران',
      en: 'Iran',
      tr: 'İran',
      ur: 'ایران',
    },
    phoneCountryCode: '98',
    supported: 'supported',
  },
  {
    alpha2: 'IQ',
    alpha3: 'IRQ',
    names: {
      ar: 'العراق',
      en: 'Iraq',
      tr: 'Irak',
      ur: 'عراق',
    },
    phoneCountryCode: '964',
    supported: 'supported',
  },
  {
    alpha2: 'IE',
    alpha3: 'IRL',
    names: {
      ar: 'أيرلندا',
      en: 'Ireland',
      tr: 'İrlanda',
      ur: 'آئرلینڈ',
    },
    phoneCountryCode: '353',
    supported: 'supported',
  },
  {
    alpha2: 'IM',
    alpha3: 'IMN',
    names: {
      ar: 'جزيرة آيل أوف مان',
      en: 'Isle of Man',
      tr: 'Man Adası',
      ur: 'آئل آف مین',
    },
    phoneCountryCode: '44',
    supported: 'supported',
  },
  {
    alpha2: 'IL',
    alpha3: 'ISR',
    names: {
      ar: 'إسرائيل',
      en: 'Israel',
      tr: 'İsrail',
      ur: 'اسرائیل',
    },
    phoneCountryCode: '972',
    supported: 'supported',
  },
  {
    alpha2: 'IT',
    alpha3: 'ITA',
    names: {
      ar: 'إيطاليا',
      en: 'Italy',
      tr: 'İtalya',
      ur: 'اٹلی',
    },
    phoneCountryCode: '39',
    supported: 'supported',
  },
  {
    alpha2: 'CI',
    alpha3: 'CIV',
    names: {
      ar: 'ساحل العاج',
      en: 'Ivory Coast',
      tr: 'Fildişi Sahili',
      ur: 'آئیوری کوسٹ',
    },
    phoneCountryCode: '225',
    supported: 'supported',
  },
  {
    alpha2: 'JM',
    alpha3: 'JAM',
    names: {
      ar: 'جامايكا',
      en: 'Jamaica',
      tr: 'Jamaika',
      ur: 'جمیکا',
    },
    phoneCountryCode: '1876',
    supported: 'supported',
  },
  {
    alpha2: 'JP',
    alpha3: 'JPN',
    names: {
      ar: 'اليابان',
      en: 'Japan',
      tr: 'Japonya',
      ur: 'جاپان',
    },
    phoneCountryCode: '81',
    supported: 'supported',
  },
  {
    alpha2: 'JE',
    alpha3: 'JEY',
    names: {
      ar: 'جيرسي',
      en: 'Jersey',
      tr: 'Jersey',
      ur: 'جرزی',
    },
    phoneCountryCode: '441534',
    supported: 'supported',
  },
  {
    alpha2: 'JO',
    alpha3: 'JOR',
    names: {
      ar: 'الأردن',
      en: 'Jordan',
      tr: 'Ürdün',
      ur: 'اردن',
    },
    phoneCountryCode: '962',
    supported: 'supported',
  },
  {
    alpha2: 'KZ',
    alpha3: 'KAZ',
    names: {
      ar: 'كازاخستان',
      en: 'Kazakhstan',
      tr: 'Kazakistan',
      ur: 'قازقستان',
    },
    phoneCountryCode: '7',
    supported: 'supported',
  },
  {
    alpha2: 'KE',
    alpha3: 'KEN',
    names: {
      ar: 'كينيا',
      en: 'Kenya',
      tr: 'Kenya',
      ur: 'کینیا',
    },
    phoneCountryCode: '254',
    supported: 'supported',
  },
  {
    alpha2: 'KI',
    alpha3: 'KIR',
    names: {
      ar: 'كيريباس',
      en: 'Kiribati',
      tr: 'Kiribati',
      ur: 'کیریباتی',
    },
    phoneCountryCode: '686',
    supported: 'supported',
  },
  {
    alpha2: 'XK',
    alpha3: 'XKX',
    names: {
      ar: 'كوسوفو',
      en: 'Kosovo',
      tr: 'Kosova',
      ur: 'کوسووو',
    },
    phoneCountryCode: '383',
    supported: 'supported',
  },
  {
    alpha2: 'KG',
    alpha3: 'KGZ',
    names: {
      ar: 'قرغيزستان',
      en: 'Kyrgyzstan',
      tr: 'Kırgızistan',
      ur: 'کرغزستان',
    },
    phoneCountryCode: '996',
    supported: 'supported',
  },
  {
    alpha2: 'LA',
    alpha3: 'LAO',
    names: {
      ar: 'لاوس',
      en: 'Laos',
      tr: 'Laos',
      ur: 'لاؤس',
    },
    phoneCountryCode: '856',
    supported: 'supported',
  },
  {
    alpha2: 'LV',
    alpha3: 'LVA',
    names: {
      ar: 'لاتفيا',
      en: 'Latvia',
      tr: 'Letonya',
      ur: 'لیٹویا',
    },
    phoneCountryCode: '371',
    supported: 'supported',
  },
  {
    alpha2: 'LB',
    alpha3: 'LBN',
    names: {
      ar: 'لبنان',
      en: 'Lebanon',
      tr: 'Lübnan',
      ur: 'لبنان',
    },
    phoneCountryCode: '961',
    supported: 'supported',
  },
  {
    alpha2: 'LS',
    alpha3: 'LSO',
    names: {
      ar: 'ليسوتو',
      en: 'Lesotho',
      tr: 'Lesotho',
      ur: 'لیسوتھو',
    },
    phoneCountryCode: '266',
    supported: 'supported',
  },
  {
    alpha2: 'LR',
    alpha3: 'LBR',
    names: {
      ar: 'ليبيريا',
      en: 'Liberia',
      tr: 'Liberya',
      ur: 'لائبیریا',
    },
    phoneCountryCode: '231',
    supported: 'supported',
  },
  {
    alpha2: 'LY',
    alpha3: 'LBY',
    names: {
      ar: 'ليبيا',
      en: 'Libya',
      tr: 'Libya',
      ur: 'لیبیا',
    },
    phoneCountryCode: '218',
    supported: 'supported',
  },
  {
    alpha2: 'LI',
    alpha3: 'LIE',
    names: {
      ar: 'ليختنشتاين',
      en: 'Liechtenstein',
      tr: 'Lihtenştayn',
      ur: 'لیختینستائن',
    },
    phoneCountryCode: '423',
    supported: 'supported',
  },
  {
    alpha2: 'LT',
    alpha3: 'LTU',
    names: {
      ar: 'ليتوانيا',
      en: 'Lithuania',
      tr: 'Litvanya',
      ur: 'لتھوانیا',
    },
    phoneCountryCode: '370',
    supported: 'supported',
  },
  {
    alpha2: 'LU',
    alpha3: 'LUX',
    names: {
      ar: 'لوكسمبورغ',
      en: 'Luxembourg',
      tr: 'Lüksemburg',
      ur: 'لکسمبرگ',
    },
    phoneCountryCode: '352',
    supported: 'supported',
  },
  {
    alpha2: 'MO',
    alpha3: 'MAC',
    names: {
      ar: 'ماكاو',
      en: 'Macau',
      tr: 'Makau',
      ur: 'مکاؤ',
    },
    phoneCountryCode: '853',
    supported: 'supported',
  },
  {
    alpha2: 'MK',
    alpha3: 'MKD',
    names: {
      ar: 'مقدونيا',
      en: 'Macedonia',
      tr: 'Makedonya',
      ur: 'مقدونیہ',
    },
    phoneCountryCode: '389',
    supported: 'supported',
  },
  {
    alpha2: 'MG',
    alpha3: 'MDG',
    names: {
      ar: 'مدغشقر',
      en: 'Madagascar',
      tr: 'Madagaskar',
      ur: 'مڈغاسکر',
    },
    phoneCountryCode: '261',
    supported: 'supported',
  },
  {
    alpha2: 'MW',
    alpha3: 'MWI',
    names: {
      ar: 'مالاوي',
      en: 'Malawi',
      tr: 'Malavi',
      ur: 'ملاوی',
    },
    phoneCountryCode: '265',
    supported: 'supported',
  },
  {
    alpha2: 'MY',
    alpha3: 'MYS',
    names: {
      ar: 'ماليزيا',
      en: 'Malaysia',
      tr: 'Malezya',
      ur: 'ملائشیا',
    },
    phoneCountryCode: '60',
    supported: 'supported',
  },
  {
    alpha2: 'MV',
    alpha3: 'MDV',
    names: {
      ar: 'جزر المالديف',
      en: 'Maldives',
      tr: 'Maldivler',
      ur: 'مالدیپ',
    },
    phoneCountryCode: '960',
    supported: 'supported',
  },
  {
    alpha2: 'ML',
    alpha3: 'MLI',
    names: {
      ar: 'مالي',
      en: 'Mali',
      tr: 'Mali',
      ur: 'مالی',
    },
    phoneCountryCode: '223',
    supported: 'supported',
  },
  {
    alpha2: 'MT',
    alpha3: 'MLT',
    names: {
      ar: 'مالطا',
      en: 'Malta',
      tr: 'Malta',
      ur: 'مالٹا',
    },
    phoneCountryCode: '356',
    supported: 'supported',
  },
  {
    alpha2: 'MH',
    alpha3: 'MHL',
    names: {
      ar: 'جزر مارشال',
      en: 'Marshall Islands',
      tr: 'Marshall Adaları',
      ur: 'جزائر مارشل',
    },
    phoneCountryCode: '692',
    supported: 'supported',
  },
  {
    alpha2: 'MR',
    alpha3: 'MRT',
    names: {
      ar: 'موريتانيا',
      en: 'Mauritania',
      tr: 'Moritanya',
      ur: 'موریتانیہ',
    },
    phoneCountryCode: '222',
    supported: 'supported',
  },
  {
    alpha2: 'MU',
    alpha3: 'MUS',
    names: {
      ar: 'موريشيوس',
      en: 'Mauritius',
      tr: 'Mauritius',
      ur: 'ماریشیس',
    },
    phoneCountryCode: '230',
    supported: 'supported',
  },
  {
    alpha2: 'YT',
    alpha3: 'MYT',
    names: {
      ar: 'مايوت',
      en: 'Mayotte',
      tr: 'Mayotte',
      ur: 'مایوٹ',
    },
    phoneCountryCode: '262',
    supported: 'supported',
  },
  {
    alpha2: 'MX',
    alpha3: 'MEX',
    names: {
      ar: 'المكسيك',
      en: 'Mexico',
      tr: 'Meksika',
      ur: 'میکسیکو',
    },
    phoneCountryCode: '52',
    supported: 'supported',
  },
  {
    alpha2: 'FM',
    alpha3: 'FSM',
    names: {
      ar: 'ميكرونيزيا',
      en: 'Micronesia',
      tr: 'Mikronezya',
      ur: 'مائیکرونیشیا',
    },
    phoneCountryCode: '691',
    supported: 'supported',
  },
  {
    alpha2: 'MD',
    alpha3: 'MDA',
    names: {
      ar: 'مولدوفا',
      en: 'Moldova',
      tr: 'Moldova',
      ur: 'مالدووا',
    },
    phoneCountryCode: '373',
    supported: 'supported',
  },
  {
    alpha2: 'MC',
    alpha3: 'MCO',
    names: {
      ar: 'موناكو',
      en: 'Monaco',
      tr: 'Monako',
      ur: 'موناکو',
    },
    phoneCountryCode: '377',
    supported: 'supported',
  },
  {
    alpha2: 'MN',
    alpha3: 'MNG',
    names: {
      ar: 'منغوليا',
      en: 'Mongolia',
      tr: 'Moğolistan',
      ur: 'منگولیا',
    },
    phoneCountryCode: '976',
    supported: 'supported',
  },
  {
    alpha2: 'ME',
    alpha3: 'MNE',
    names: {
      ar: 'الجبل الأسود',
      en: 'Montenegro',
      tr: 'Karadağ',
      ur: 'مونٹینیگرو',
    },
    phoneCountryCode: '382',
    supported: 'supported',
  },
  {
    alpha2: 'MS',
    alpha3: 'MSR',
    names: {
      ar: 'مونتسيرات',
      en: 'Montserrat',
      tr: 'Montserrat',
      ur: 'مونٹسیریٹ',
    },
    phoneCountryCode: '1664',
    supported: 'supported',
  },
  {
    alpha2: 'MA',
    alpha3: 'MAR',
    names: {
      ar: 'المغرب',
      en: 'Morocco',
      tr: 'Fas',
      ur: 'موراکو',
    },
    phoneCountryCode: '212',
    supported: 'supported',
  },
  {
    alpha2: 'MZ',
    alpha3: 'MOZ',
    names: {
      ar: 'موزمبيق',
      en: 'Mozambique',
      tr: 'Mozambik',
      ur: 'موزمبیق',
    },
    phoneCountryCode: '258',
    supported: 'supported',
  },
  {
    alpha2: 'MM',
    alpha3: 'MMR',
    names: {
      ar: 'ميانمار',
      en: 'Myanmar',
      tr: 'Myanmar',
      ur: 'میانمار',
    },
    phoneCountryCode: '95',
    supported: 'supported',
  },
  {
    alpha2: 'NA',
    alpha3: 'NAM',
    names: {
      ar: 'ناميبيا',
      en: 'Namibia',
      tr: 'Namibya',
      ur: 'نامیبیا',
    },
    phoneCountryCode: '264',
    supported: 'supported',
  },
  {
    alpha2: 'NR',
    alpha3: 'NRU',
    names: {
      ar: 'ناورو',
      en: 'Nauru',
      tr: 'Nauru',
      ur: 'ناورو',
    },
    phoneCountryCode: '674',
    supported: 'supported',
  },
  {
    alpha2: 'NP',
    alpha3: 'NPL',
    names: {
      ar: 'نيبال',
      en: 'Nepal',
      tr: 'Nepal',
      ur: 'نیپال',
    },
    phoneCountryCode: '977',
    supported: 'supported',
  },
  {
    alpha2: 'NL',
    alpha3: 'NLD',
    names: {
      ar: 'هولندا',
      en: 'Netherlands',
      tr: 'Hollanda',
      ur: 'ہالینڈ',
    },
    phoneCountryCode: '31',
    supported: 'supported',
  },
  {
    alpha2: 'NC',
    alpha3: 'NCL',
    names: {
      ar: 'كاليدونيا الجديدة',
      en: 'New Caledonia',
      tr: 'Yeni Kaledonya',
      ur: 'نیو کیلیڈونیا',
    },
    phoneCountryCode: '687',
    supported: 'supported',
  },
  {
    alpha2: 'NZ',
    alpha3: 'NZL',
    names: {
      ar: 'نيوزيلاندا',
      en: 'New Zealand',
      tr: 'Yeni Zelanda',
      ur: 'نیوزی لینڈ',
    },
    phoneCountryCode: '64',
    supported: 'supported',
  },
  {
    alpha2: 'NI',
    alpha3: 'NIC',
    names: {
      ar: 'نيكاراغوا',
      en: 'Nicaragua',
      tr: 'Nikaragua',
      ur: 'نکاراگوا',
    },
    phoneCountryCode: '505',
    supported: 'supported',
  },
  {
    alpha2: 'NE',
    alpha3: 'NER',
    names: {
      ar: 'النيجر',
      en: 'Niger',
      tr: 'Nijer',
      ur: 'نائجر',
    },
    phoneCountryCode: '227',
    supported: 'supported',
  },
  {
    alpha2: 'NG',
    alpha3: 'NGA',
    names: {
      ar: 'نيجيريا',
      en: 'Nigeria',
      tr: 'Nijerya',
      ur: 'نائیجیریا',
    },
    phoneCountryCode: '234',
    supported: 'supported',
  },
  {
    alpha2: 'NU',
    alpha3: 'NIU',
    names: {
      ar: 'نيوي',
      en: 'Niue',
      tr: 'Niue',
      ur: 'نیووے',
    },
    phoneCountryCode: '683',
    supported: 'supported',
  },
  {
    alpha2: 'NF',
    alpha3: 'NFK',
    names: {
      ar: 'جزيرة نورفولك',
      en: 'Norfolk Island',
      tr: 'Norfolk Adası',
      ur: 'جزیرہ نارفلاک',
    },
    phoneCountryCode: '672',
    supported: 'supported',
  },
  {
    alpha2: 'KP',
    alpha3: 'PRK',
    names: {
      ar: 'كوريا الشمالية',
      en: 'North Korea',
      tr: 'Kuzey Kore',
      ur: 'شمالی کوریا',
    },
    phoneCountryCode: '850',
    supported: 'supported',
  },
  {
    alpha2: 'MP',
    alpha3: 'MNP',
    names: {
      ar: 'جزر مريانا الشمالية',
      en: 'Northern Mariana Islands',
      tr: 'Kuzey Mariana Adaları',
      ur: 'جزائر شمالی ماریانا',
    },
    phoneCountryCode: '1670',
    supported: 'supported',
  },
  {
    alpha2: 'NO',
    alpha3: 'NOR',
    names: {
      ar: 'النرويج',
      en: 'Norway',
      tr: 'Norveç',
      ur: 'ناروے',
    },
    phoneCountryCode: '47',
    supported: 'supported',
  },
  {
    alpha2: 'PK',
    alpha3: 'PAK',
    names: {
      ar: 'باكستان',
      en: 'Pakistan',
      tr: 'Pakistan',
      ur: 'پاکستان',
    },
    phoneCountryCode: '92',
    supported: 'supported',
  },
  {
    alpha2: 'PW',
    alpha3: 'PLW',
    names: {
      ar: 'بالاو',
      en: 'Palau',
      tr: 'Palau',
      ur: 'پلاؤ',
    },
    phoneCountryCode: '680',
    supported: 'supported',
  },
  {
    alpha2: 'PS',
    alpha3: 'PSE',
    names: {
      ar: 'فلسطين',
      en: 'Palestine',
      tr: 'Filistin',
      ur: 'فلسطین',
    },
    phoneCountryCode: '970',
    supported: 'supported',
  },
  {
    alpha2: 'PA',
    alpha3: 'PAN',
    names: {
      ar: 'بناما',
      en: 'Panama',
      tr: 'Panama',
      ur: 'پاناما',
    },
    phoneCountryCode: '507',
    supported: 'supported',
  },
  {
    alpha2: 'PG',
    alpha3: 'PNG',
    names: {
      ar: 'بابوا غينيا الجديدة',
      en: 'Papua New Guinea',
      tr: 'Papua Yeni Gine',
      ur: 'پاپوا نیو گنی',
    },
    phoneCountryCode: '675',
    supported: 'supported',
  },
  {
    alpha2: 'PY',
    alpha3: 'PRY',
    names: {
      ar: 'باراغواي',
      en: 'Paraguay',
      tr: 'Paraguay',
      ur: 'پیراگوئے',
    },
    phoneCountryCode: '595',
    supported: 'supported',
  },
  {
    alpha2: 'PE',
    alpha3: 'PER',
    names: {
      ar: 'بيرو',
      en: 'Peru',
      tr: 'Peru',
      ur: 'پیرو',
    },
    phoneCountryCode: '51',
    supported: 'supported',
  },
  {
    alpha2: 'PH',
    alpha3: 'PHL',
    names: {
      ar: 'الفلبين',
      en: 'Philippines',
      tr: 'Filipinler',
      ur: 'فلپائن',
    },
    phoneCountryCode: '63',
    supported: 'supported',
  },
  {
    alpha2: 'PN',
    alpha3: 'PCN',
    names: {
      ar: 'بيتكيرن',
      en: 'Pitcairn',
      tr: 'Pitcairn',
      ur: 'جزائر پٹکیرن',
    },
    phoneCountryCode: '64',
    supported: 'supported',
  },
  {
    alpha2: 'PL',
    alpha3: 'POL',
    names: {
      ar: 'بولندا',
      en: 'Poland',
      tr: 'Polonya',
      ur: 'پولینڈ',
    },
    phoneCountryCode: '48',
    supported: 'supported',
  },
  {
    alpha2: 'PT',
    alpha3: 'PRT',
    names: {
      ar: 'البرتغال',
      en: 'Portugal',
      tr: 'Portekiz',
      ur: 'پرتگال',
    },
    phoneCountryCode: '351',
    supported: 'supported',
  },
  {
    alpha2: 'PR',
    alpha3: 'PRI',
    names: {
      ar: 'بورتوريكو',
      en: 'Puerto Rico',
      tr: 'Porto Riko',
      ur: 'پورٹو ریکو',
    },
    phoneCountryCode: '1',
    supported: 'supported',
  },
  {
    alpha2: 'CG',
    alpha3: 'COG',
    names: {
      ar: 'جمهورية الكونغو',
      en: 'Republic of the Congo',
      tr: 'Kongo Cumhuriyeti',
      ur: 'جمہوریہ کانگو',
    },
    phoneCountryCode: '242',
    supported: 'supported',
  },
  {
    alpha2: 'RO',
    alpha3: 'ROU',
    names: {
      ar: 'رومانيا',
      en: 'Romania',
      tr: 'Romanya',
      ur: 'رومانیہ',
    },
    phoneCountryCode: '40',
    supported: 'supported',
  },
  {
    alpha2: 'RU',
    alpha3: 'RUS',
    names: {
      ar: 'روسيا',
      en: 'Russia',
      tr: 'Rusya',
      ur: 'روس',
    },
    phoneCountryCode: '7',
    supported: 'supported',
  },
  {
    alpha2: 'RW',
    alpha3: 'RWA',
    names: {
      ar: 'رواندا',
      en: 'Rwanda',
      tr: 'Ruanda',
      ur: 'روانڈا',
    },
    phoneCountryCode: '250',
    supported: 'supported',
  },
  {
    alpha2: 'SH',
    alpha3: 'SHN',
    names: {
      ar: 'سانت هيلانة',
      en: 'Saint Helena',
      tr: 'Saint Helena',
      ur: 'سینٹ ہلینا',
    },
    phoneCountryCode: '290',
    supported: 'supported',
  },
  {
    alpha2: 'KN',
    alpha3: 'KNA',
    names: {
      ar: 'سانت كيتس ونيفيس',
      en: 'Saint Kitts and Nevis',
      tr: 'Saint Kitts ve Nevis',
      ur: 'سینٹ کیٹز و ناویس',
    },
    phoneCountryCode: '1869',
    supported: 'supported',
  },
  {
    alpha2: 'LC',
    alpha3: 'LCA',
    names: {
      ar: 'القديسة لوسيا',
      en: 'Saint Lucia',
      tr: 'Saint Lucia',
      ur: 'سینٹ لوسیا',
    },
    phoneCountryCode: '1758',
    supported: 'supported',
  },
  {
    alpha2: 'MF',
    alpha3: 'MAF',
    names: {
      ar: 'القديس مارتن',
      en: 'Saint Martin',
      tr: 'Saint Martin',
      ur: 'سینٹ مارٹن',
    },
    phoneCountryCode: '590',
    supported: 'supported',
  },
  {
    alpha2: 'PM',
    alpha3: 'SPM',
    names: {
      ar: 'سانت بيير وميكلون',
      en: 'Saint Pierre and Miquelon',
      tr: 'Saint Pierre ve Miquelon',
      ur: 'سینٹ پیئر و میکیلون',
    },
    phoneCountryCode: '508',
    supported: 'supported',
  },
  {
    alpha2: 'VC',
    alpha3: 'VCT',
    names: {
      ar: 'سانت فنسنت وجزر غرينادين',
      en: 'Saint Vincent and the Grenadines',
      tr: 'Saint Vincent ve Grenadinler',
      ur: 'سینٹ وینسینٹ و گریناڈائنز',
    },
    phoneCountryCode: '1784',
    supported: 'supported',
  },
  {
    alpha2: 'WS',
    alpha3: 'WSM',
    names: {
      ar: 'ساموا',
      en: 'Samoa',
      tr: 'Samoa',
      ur: 'سامووا',
    },
    phoneCountryCode: '685',
    supported: 'supported',
  },
  {
    alpha2: 'SM',
    alpha3: 'SMR',
    names: {
      ar: 'سان مارينو',
      en: 'San Marino',
      tr: 'San Marino',
      ur: 'سان مارینو',
    },
    phoneCountryCode: '378',
    supported: 'supported',
  },
  {
    alpha2: 'ST',
    alpha3: 'STP',
    names: {
      ar: 'ساو تومي وبرنسيبي',
      en: 'Sao Tome and Principe',
      tr: 'Sao Tome ve Principe',
      ur: 'ساؤ ٹومے و پرنسپے',
    },
    phoneCountryCode: '239',
    supported: 'supported',
  },
  {
    alpha2: 'SN',
    alpha3: 'SEN',
    names: {
      ar: 'السنغال',
      en: 'Senegal',
      tr: 'Senegal',
      ur: 'سینیگال',
    },
    phoneCountryCode: '221',
    supported: 'supported',
  },
  {
    alpha2: 'RS',
    alpha3: 'SRB',
    names: {
      ar: 'صربيا',
      en: 'Serbia',
      tr: 'Sırbistan',
      ur: 'سربیا',
    },
    phoneCountryCode: '381',
    supported: 'supported',
  },
  {
    alpha2: 'SC',
    alpha3: 'SYC',
    names: {
      ar: 'سيشيل',
      en: 'Seychelles',
      tr: 'Seyşeller',
      ur: 'سیچیلیس',
    },
    phoneCountryCode: '248',
    supported: 'supported',
  },
  {
    alpha2: 'SL',
    alpha3: 'SLE',
    names: {
      ar: 'سيرا ليون',
      en: 'Sierra Leone',
      tr: 'Sierra Leone',
      ur: 'سیرالیون',
    },
    phoneCountryCode: '232',
    supported: 'supported',
  },
  {
    alpha2: 'SG',
    alpha3: 'SGP',
    names: {
      ar: 'سنغافورة',
      en: 'Singapore',
      tr: 'Singapur',
      ur: 'سنگاپور',
    },
    phoneCountryCode: '65',
    supported: 'supported',
  },
  {
    alpha2: 'SX',
    alpha3: 'SXM',
    names: {
      ar: 'سينت مارتن',
      en: 'Sint Maarten',
      tr: 'Saint Martin',
      ur: 'سنٹ مارٹن',
    },
    phoneCountryCode: '1721',
    supported: 'supported',
  },
  {
    alpha2: 'SK',
    alpha3: 'SVK',
    names: {
      ar: 'سلوفاكيا',
      en: 'Slovakia',
      tr: 'Slovakya',
      ur: 'سلوواکیہ',
    },
    phoneCountryCode: '421',
    supported: 'supported',
  },
  {
    alpha2: 'SI',
    alpha3: 'SVN',
    names: {
      ar: 'سلوفينيا',
      en: 'Slovenia',
      tr: 'Slovenya',
      ur: 'سلووینیا',
    },
    phoneCountryCode: '386',
    supported: 'supported',
  },
  {
    alpha2: 'SB',
    alpha3: 'SLB',
    names: {
      ar: 'جزر سليمان',
      en: 'Solomon Islands',
      tr: 'Solomon Adaları',
      ur: 'جزائر سلیمان',
    },
    phoneCountryCode: '677',
    supported: 'supported',
  },
  {
    alpha2: 'SO',
    alpha3: 'SOM',
    names: {
      ar: 'الصومال',
      en: 'Somalia',
      tr: 'Somali',
      ur: 'صومالیہ',
    },
    phoneCountryCode: '252',
    supported: 'supported',
  },
  {
    alpha2: 'ZA',
    alpha3: 'ZAF',
    names: {
      ar: 'جنوب أفريقيا',
      en: 'South Africa',
      tr: 'Güney Afrika',
      ur: 'جنوبی افریقہ',
    },
    phoneCountryCode: '27',
    supported: 'supported',
  },
  {
    alpha2: 'KR',
    alpha3: 'KOR',
    names: {
      ar: 'كوريا الجنوبية',
      en: 'South Korea',
      tr: 'Güney Kore',
      ur: 'جنوبی کوریا',
    },
    phoneCountryCode: '82',
    supported: 'supported',
  },
  {
    alpha2: 'SS',
    alpha3: 'SSD',
    names: {
      ar: 'جنوب السودان',
      en: 'South Sudan',
      tr: 'Güney Sudan',
      ur: 'جنوبی سوڈان',
    },
    phoneCountryCode: '211',
    supported: 'supported',
  },
  {
    alpha2: 'ES',
    alpha3: 'ESP',
    names: {
      ar: 'إسبانيا',
      en: 'Spain',
      tr: 'İspanya',
      ur: 'اسپین',
    },
    phoneCountryCode: '34',
    supported: 'supported',
  },
  {
    alpha2: 'LK',
    alpha3: 'LKA',
    names: {
      ar: 'سيريلانكا',
      en: 'Sri Lanka',
      tr: 'Sri Lanka',
      ur: 'سری لنکا',
    },
    phoneCountryCode: '94',
    supported: 'supported',
  },
  {
    alpha2: 'SD',
    alpha3: 'SDN',
    names: {
      ar: 'سودان',
      en: 'Sudan',
      tr: 'Sudan',
      ur: 'سوڈان',
    },
    phoneCountryCode: '249',
    supported: 'supported',
  },
  {
    alpha2: 'SR',
    alpha3: 'SUR',
    names: {
      ar: 'سورينام',
      en: 'Suriname',
      tr: 'Surinam',
      ur: 'سرینام',
    },
    phoneCountryCode: '597',
    supported: 'supported',
  },
  {
    alpha2: 'SJ',
    alpha3: 'SJM',
    names: {
      ar: 'سفالبارد وجان مايان',
      en: 'Svalbard and Jan Mayen',
      tr: 'Svalbard ve Jan Mayen',
      ur: 'سوالبارڈ اور جان ماین',
    },
    phoneCountryCode: '47',
    supported: 'supported',
  },
  {
    alpha2: 'SZ',
    alpha3: 'SWZ',
    names: {
      ar: 'سوازيلاند',
      en: 'Swaziland',
      tr: 'Svaziland',
      ur: 'سوازی لینڈ',
    },
    phoneCountryCode: '268',
    supported: 'supported',
  },
  {
    alpha2: 'SE',
    alpha3: 'SWE',
    names: {
      ar: 'السويد',
      en: 'Sweden',
      tr: 'İsveç',
      ur: 'سویڈن',
    },
    phoneCountryCode: '46',
    supported: 'supported',
  },
  {
    alpha2: 'CH',
    alpha3: 'CHE',
    names: {
      ar: 'سويسرا',
      en: 'Switzerland',
      tr: 'İsviçre',
      ur: 'سوئٹزرلینڈ',
    },
    phoneCountryCode: '41',
    supported: 'supported',
  },
  {
    alpha2: 'SY',
    alpha3: 'SYR',
    names: {
      ar: 'سوريا',
      en: 'Syria',
      tr: 'Suriye',
      ur: 'شام',
    },
    phoneCountryCode: '963',
    supported: 'supported',
  },
  {
    alpha2: 'TW',
    alpha3: 'TWN',
    names: {
      ar: 'تايوان',
      en: 'Taiwan',
      tr: 'Tayvan',
      ur: 'تائیوان',
    },
    phoneCountryCode: '886',
    supported: 'supported',
  },
  {
    alpha2: 'TJ',
    alpha3: 'TJK',
    names: {
      ar: 'طاجيكستان',
      en: 'Tajikistan',
      tr: 'Tacikistan',
      ur: 'تاجکستان',
    },
    phoneCountryCode: '992',
    supported: 'supported',
  },
  {
    alpha2: 'TZ',
    alpha3: 'TZA',
    names: {
      ar: 'تنزانيا',
      en: 'Tanzania',
      tr: 'Tanzanya',
      ur: 'تنزانیہ',
    },
    phoneCountryCode: '255',
    supported: 'supported',
  },
  {
    alpha2: 'TH',
    alpha3: 'THA',
    names: {
      ar: 'تايلاند',
      en: 'Thailand',
      tr: 'Tayland',
      ur: 'تھائی لینڈ',
    },
    phoneCountryCode: '66',
    supported: 'supported',
  },
  {
    alpha2: 'TG',
    alpha3: 'TGO',
    names: {
      ar: 'توغو',
      en: 'Togo',
      tr: 'Togo',
      ur: 'ٹوگو',
    },
    phoneCountryCode: '228',
    supported: 'supported',
  },
  {
    alpha2: 'TK',
    alpha3: 'TKL',
    names: {
      ar: 'توكيلاو',
      en: 'Tokelau',
      tr: 'Tokelau',
      ur: 'ٹوکیلاؤ',
    },
    phoneCountryCode: '690',
    supported: 'supported',
  },
  {
    alpha2: 'TO',
    alpha3: 'TON',
    names: {
      ar: 'تونغا',
      en: 'Tonga',
      tr: 'Tonga',
      ur: 'ٹونگا',
    },
    phoneCountryCode: '676',
    supported: 'supported',
  },
  {
    alpha2: 'TT',
    alpha3: 'TTO',
    names: {
      ar: 'ترينداد وتوباغو',
      en: 'Trinidad and Tobago',
      tr: 'Trinidad ve Tobago',
      ur: 'ٹرینیڈاڈ اور ٹوباگو',
    },
    phoneCountryCode: '1868',
    supported: 'supported',
  },
  {
    alpha2: 'TN',
    alpha3: 'TUN',
    names: {
      ar: 'تونس',
      en: 'Tunisia',
      tr: 'Tunus',
      ur: 'تونس',
    },
    phoneCountryCode: '216',
    supported: 'supported',
  },
  {
    alpha2: 'TM',
    alpha3: 'TKM',
    names: {
      ar: 'تركمانستان',
      en: 'Turkmenistan',
      tr: 'Türkmenistan',
      ur: 'ترکمانستان',
    },
    phoneCountryCode: '993',
    supported: 'supported',
  },
  {
    alpha2: 'TC',
    alpha3: 'TCA',
    names: {
      ar: 'جزر تركس وكايكوس',
      en: 'Turks and Caicos Islands',
      tr: 'Turks ve Caicos Adaları',
      ur: 'جزائر کیکس و ترکیہ',
    },
    phoneCountryCode: '1649',
    supported: 'supported',
  },
  {
    alpha2: 'TV',
    alpha3: 'TUV',
    names: {
      ar: 'توفالو',
      en: 'Tuvalu',
      tr: 'Tuvalu',
      ur: 'ٹوالو',
    },
    phoneCountryCode: '688',
    supported: 'supported',
  },
  {
    alpha2: 'VI',
    alpha3: 'VIR',
    names: {
      ar: 'جزر فيرجن الأمريكية',
      en: 'U.S. Virgin Islands',
      tr: 'ABD Virgin Adaları',
      ur: 'امریکی جزائر ورجن',
    },
    phoneCountryCode: '1340',
    supported: 'supported',
  },
  {
    alpha2: 'UG',
    alpha3: 'UGA',
    names: {
      ar: 'أوغندا',
      en: 'Uganda',
      tr: 'Uganda',
      ur: 'یوگنڈا',
    },
    phoneCountryCode: '256',
    supported: 'supported',
  },
  {
    alpha2: 'UA',
    alpha3: 'UKR',
    names: {
      ar: 'أوكرانيا',
      en: 'Ukraine',
      tr: 'Ukrayna',
      ur: 'یوکرین',
    },
    phoneCountryCode: '380',
    supported: 'supported',
  },
  {
    alpha2: 'GB',
    alpha3: 'GBR',
    names: {
      ar: 'المملكة المتحدة',
      en: 'United Kingdom',
      tr: 'Birleşik Krallık',
      ur: 'برطانیہ',
    },
    phoneCountryCode: '44',
    supported: 'supported',
  },
  {
    alpha2: 'US',
    alpha3: 'USA',
    names: {
      ar: 'الولايات المتحدة',
      en: 'United States',
      tr: 'Amerika Birleşik Devletleri',
      ur: 'امریکا',
    },
    phoneCountryCode: '1',
    supported: 'supported',
  },
  {
    alpha2: 'UY',
    alpha3: 'URY',
    names: {
      ar: 'أوروغواي',
      en: 'Uruguay',
      tr: 'Uruguay',
      ur: 'یوراگوئے',
    },
    phoneCountryCode: '598',
    supported: 'supported',
  },
  {
    alpha2: 'UZ',
    alpha3: 'UZB',
    names: {
      ar: 'أوزبكستان',
      en: 'Uzbekistan',
      tr: 'Özbekistan',
      ur: 'ازبکستان',
    },
    phoneCountryCode: '998',
    supported: 'supported',
  },
  {
    alpha2: 'VU',
    alpha3: 'VUT',
    names: {
      ar: 'فانواتو',
      en: 'Vanuatu',
      tr: 'Vanuatu',
      ur: 'وانواتو',
    },
    phoneCountryCode: '678',
    supported: 'supported',
  },
  {
    alpha2: 'VA',
    alpha3: 'VAT',
    names: {
      ar: 'الفاتيكان',
      en: 'Vatican',
      tr: 'Vatikan',
      ur: 'ویٹیکن',
    },
    phoneCountryCode: '379',
    supported: 'supported',
  },
  {
    alpha2: 'VE',
    alpha3: 'VEN',
    names: {
      ar: 'فنزويلا',
      en: 'Venezuela',
      tr: 'Venezuela',
      ur: 'وینیزویلا',
    },
    phoneCountryCode: '58',
    supported: 'supported',
  },
  {
    alpha2: 'VN',
    alpha3: 'VNM',
    names: {
      ar: 'فيتنام',
      en: 'Vietnam',
      tr: 'Vietnam',
      ur: 'ویتنام',
    },
    phoneCountryCode: '84',
    supported: 'supported',
  },
  {
    alpha2: 'WF',
    alpha3: 'WLF',
    names: {
      ar: 'واليس وفوتونا',
      en: 'Wallis and Futuna',
      tr: 'Wallis ve Futuna Adaları',
      ur: 'والس و فتونہ',
    },
    phoneCountryCode: '681',
    supported: 'supported',
  },
  {
    alpha2: 'EH',
    alpha3: 'ESH',
    names: {
      ar: 'الصحراء الغربية',
      en: 'Western Sahara',
      tr: 'Batı Sahara',
      ur: 'مغربی صحارا',
    },
    phoneCountryCode: '212',
    supported: 'supported',
  },
  {
    alpha2: 'YE',
    alpha3: 'YEM',
    names: {
      ar: 'اليمن',
      en: 'Yemen',
      tr: 'Yemen',
      ur: 'یمن',
    },
    phoneCountryCode: '967',
    supported: 'supported',
  },
  {
    alpha2: 'ZM',
    alpha3: 'ZMB',
    names: {
      ar: 'زامبيا',
      en: 'Zambia',
      tr: 'Zambiya',
      ur: 'زیمبیا',
    },
    phoneCountryCode: '260',
    supported: 'supported',
  },
  {
    alpha2: 'ZW',
    alpha3: 'ZWE',
    names: {
      ar: 'زيمبابوي',
      en: 'Zimbabwe',
      tr: 'Zimbabve',
      ur: 'زمبابوے',
    },
    phoneCountryCode: '263',
    supported: 'supported',
  },
  {
    alpha2: 'AX',
    alpha3: 'ALA',
    names: {
      ar: 'جزر آلاند',
      en: 'Åland Islands',
      tr: 'Aland adaları',
      ur: 'جزیرہ آلینڈ',
    },
    phoneCountryCode: '358',
    supported: 'supported',
  },
];
