import Axios from 'axios';

export const nextApi = Axios.create({
  baseURL: '/api',
  headers: {
    common: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
  withCredentials: true,
});
