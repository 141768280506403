import { useEffect } from 'react';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';

import { getLangDirection } from '@rainhq/helpers';

import {
  BrandLogo,
  BrandLogoType,
  ELogoSizes,
  BackgroundFill,
  PatternStyle,
  PatternFadeStyle,
  Color,
} from '@rainhq/ui-library';

import { Language } from 'enums/language';

import { GlobalStyles } from './GlobalStyles';
import { Wrapper, ContentWrapper, HeaderWrapper } from './Default.styles';

import { getEnvVars } from 'services/vars';

export interface IFooterConfig {
  about: string;
  storesEnabled?: boolean;
  socialsEnabled?: boolean;
  languageSelectorEnabled?: boolean;
}

interface IProps {
  children: React.ReactNode;
  isWebView: boolean;
}

const Layout: React.FC<IProps> = ({ children, isWebView }: IProps) => {
  const { i18n, t } = useTranslation(['common']);
  const currentLanguage = i18n.language as Language;

  const dir = getLangDirection(currentLanguage);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.dir = dir;
  }, [dir, i18n.language]);

  const BRAND_LOGO_DESCRIPTIVE_TEXT = 'Rain · Buy & Sell Bitcoin, Ethereum, and more in the Middle East';

  return (
    <>
      <GlobalStyles />
      <Head>
        <title>{t('app-title')}</title>
      </Head>
      <Wrapper>
        <BackgroundFill
          altColors
          radialGradient
          pattern
          patternStyle={PatternStyle.Open}
          patternFadeStyle={PatternFadeStyle.BottomLeft}
          backgroundColor={Color.SurfaceDark}
        >
          <HeaderWrapper isWebView={isWebView}>
            <a className="logo-link" href={getEnvVars().NEXT_PUBLIC_RAIN_URL} aria-label={BRAND_LOGO_DESCRIPTIVE_TEXT}>
              <BrandLogo
                type={BrandLogoType.Default}
                height={ELogoSizes.Medium}
                alt={BRAND_LOGO_DESCRIPTIVE_TEXT}
                altColors
              />
            </a>
          </HeaderWrapper>
          <ContentWrapper>{children}</ContentWrapper>
        </BackgroundFill>
      </Wrapper>
    </>
  );
};

export { Layout };
