import { ILanguageOption } from '@rainhq/models';

import { Language } from 'enums/language';

const LANGUAGES_DICTIONARY: Record<string, ILanguageOption> = {
  [Language.English]: { abbreviation: Language.English, name: 'English' },
  [Language.Arabic]: { abbreviation: Language.Arabic, name: 'العربية' },
  [Language.Turkish]: { abbreviation: Language.Turkish, name: 'Türkçe' },
};

export const getLanguageOptions = (): ILanguageOption[] => {
  return Object.values(LANGUAGES_DICTIONARY);
};
