import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { P, Title as TitleComponent, Label, Heading, Panel, Col } from '@rainhq/ui-library';

const headerHeight = '78px';

export const Wrapper = styled.div(
  ({ theme }) => css`
    margin: 0;
    padding: 0;

    ${theme.breakpoints.medium} {
      margin: 0 auto ${theme.spaces.xxxlarge};
      padding: 0 ${theme.spaces.xxlarge} 0;
    }
  `,
);

export const ActionColumn = styled.div<{ rightAlign?: boolean }>(
  ({ theme, rightAlign }) => css`
    padding-right: ${theme.spaces.medium};
    color: ${theme.colors.background};
    display: none;
    position: absolute;

    button {
      transform: ${rightAlign ? 'rotate(180deg)' : 'none'};

      &:hover {
        background-color: ${theme.altColors.background};
      }
    }

    ${theme.breakpoints.medium} {
      display: block;
      margin-left: ${theme.spaces.medium};
      left: 0;
      top: 0;
    }
  `,
);

export const ContentPanel = styled(Panel)`
  max-width: 100vw;
  width: 500px;
  margin: 0 auto;
  padding: 0;
`;

export const Header = styled.div(
  ({ theme }) => css`
    padding: ${theme.spaces.xxlarge};
    padding-bottom: 0;
    min-height: ${headerHeight};
  `,
);

export const Body = styled(Col)<{ bottom?: boolean }>(
  ({ theme, bottom }) => css`
    color: ${theme.colors.onBackground};
    padding: ${theme.spaces.xxlarge};
    padding-top: ${bottom ? theme.spaces.xlarge : theme.spaces.xxlarge};
    line-height: 1.5;
  `,
);

export const Title = styled(TitleComponent)(
  ({ theme }) => css`
    color: ${theme.colors.onBackground};
    padding-bottom: ${theme.spaces.medium};
  `,
);

export const SubTitle = styled(P)(
  ({ theme }) => css`
    color: ${theme.colors.onBackground};
  `,
);

export const CountryLabel = styled(Heading)(
  ({ theme }) => css`
    color: ${theme.colors.onBackground};
    padding-bottom: ${theme.spaces.large};
  `,
);

export const FormFieldWrapper = styled.div(
  ({ theme }) => css`
    margin: ${theme.spaces.small} 0;
  `,
);

export const CenteredDiv = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spaces.large};
    text-align: center;
    a {
      text-decoration: none;
    }
  `,
);

export const Divider = styled(Label)(
  ({ theme }) => css`
    position: relative;
    margin: 0 auto;
    color: ${theme.colors.onBackground};
    display: flex;
    width: calc(100% - calc(2 * ${theme.spaces.xxlarge}));
    align-items: center;

    &::before,
    &::after {
      content: '';
      width: 100%;
      height: 1px;
      border-top: 1px dashed #c0bfc3;
    }

    &::before {
      left: 0;
      margin-right: ${theme.spaces.small};
    }

    &::after {
      right: 0;
      margin-left: ${theme.spaces.small};
    }
  `,
);

export const LanguageSelectWrapper = styled.div(
  ({ theme }) => css`
    display: block;
    margin: ${theme.spaces.xxlarge} auto 0;
    padding-bottom: ${theme.spaces.xxlarge};
    color: white;
    text-align: center;

    div {
      display: inline-block;
    }
  `,
);
