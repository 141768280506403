import getConfig from 'next/config';

export interface EnvVars {
  NEXT_PUBLIC_ROOT_URL: string;
  NEXT_PUBLIC_RAIN_URL: string;
  NEXT_PUBLIC_RAIN_DOMAIN: string;
  NEXT_PUBLIC_API_URL: string;
  NEXT_PUBLIC_API_KEY: string;
  NEXT_PUBLIC_API_SECRET: string;
  CONTENTFUL_SPACE_ID: string;
  CONTENTFUL_ENVIRONMENT: string;
  CONTENTFUL_CDA_TOKEN: string;
  NEXT_PUBLIC_TR_FRESHDESK_LOGIN_URL: string;
  NEXT_PUBLIC_BH_FRESHDESK_LOGIN_URL: string;
  NEXT_PUBLIC_AE_FRESHDESK_LOGIN_URL: string;
  NEXT_PUBLIC_TR_FRESHDESK_AUTH_REDIRECT_URL: string;
  NEXT_PUBLIC_BH_FRESHDESK_AUTH_REDIRECT_URL: string;
  NEXT_PUBLIC_AE_FRESHDESK_AUTH_REDIRECT_URL: string;
  NEXT_PUBLIC_TR_FRESHDESK_ACCOUNT: string;
  NEXT_PUBLIC_BH_FRESHDESK_ACCOUNT: string;
  NEXT_PUBLIC_AE_FRESHDESK_ACCOUNT: string;
}

export const getEnvVars = () => {
  const { publicRuntimeConfig } = getConfig() as { publicRuntimeConfig: EnvVars };
  return publicRuntimeConfig;
};
