import { useTranslation } from 'next-i18next';

import { Color, EIconSizes, IconButton, ArrowBackIcon, LanguageSelect } from '@rainhq/ui-library';

import { Language } from 'enums/language';
import { getLanguageOptions } from 'services/i18n';

import { Layout } from 'library/layouts/Default';
import { ContentPanel, Wrapper, ActionColumn, LanguageSelectWrapper } from './SupportRouter.styles';

interface IProps {
  children: React.ReactNode;
  isWebView: boolean;
}

const SupportRouter: React.FC<IProps> = ({ children, isWebView }: IProps) => {
  const { i18n } = useTranslation(['common']);

  const languageOptions = getLanguageOptions();
  const handleLanguageChange = (language: Language) => {
    window.location.replace(`/${language}`);
  };

  return (
    <Layout isWebView={isWebView}>
      <Wrapper>
        <ActionColumn rightAlign={i18n.dir(i18n.language) === 'rtl'}>
          <IconButton onClick={() => history.back()}>
            <ArrowBackIcon size={EIconSizes.Small} />
          </IconButton>
        </ActionColumn>
        <ContentPanel backgroundColor={Color.Surface}>{children}</ContentPanel>
        <LanguageSelectWrapper>
          <LanguageSelect
            currentLocale={i18n.language as Language}
            languages={languageOptions}
            onChange={handleLanguageChange}
            altColors
          />
        </LanguageSelectWrapper>
      </Wrapper>
    </Layout>
  );
};

export { SupportRouter };
