import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { hasCookie, deleteCookie } from 'cookies-next';

import { ICountry } from '@rainhq/models';
import { Button, Grid, EHeading, CountrySelect } from '@rainhq/ui-library';

import { nextApi } from 'config/nextApi';
import { IJurisdictionResponse } from 'types/core';
import { Language } from 'enums/language';
import { CookieName } from 'enums/cookies';
import { ResourceMap } from 'services/contentful';

import { CenteredDiv, Body, FormFieldWrapper, CountryLabel } from 'library/layouts/SupportRouter/SupportRouter.styles';
import { getRedirectDestination } from 'services/freshdesk';
import { getEnvVars } from 'services/vars';

export interface ILanguageOption {
  abbreviation: Language;
  name: string;
}
export interface ICountrySelectRouter {
  'data-testid'?: string;
  countries: ICountry[];
  value?: ICountry;
  resourceMap?: ResourceMap;
  resourceKey: string;
}

const CountrySelectRouter: React.FC<ICountrySelectRouter> = ({
  'data-testid': testId = 'countrySelect',
  countries,
  value,
  resourceMap,
  resourceKey,
}) => {
  const { i18n, t } = useTranslation(['common']);
  const [selectedCountry, setSelectedCountry] = useState<ICountry | undefined>(value || undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const onSubmit = async () => {
    if (selectedCountry) {
      setIsSubmitting(true);
      try {
        const { data } = await nextApi.get<IJurisdictionResponse>(
          `/jurisdiction?&country=${selectedCountry ? selectedCountry.alpha2 : ''}`,
        );

        const destination = getRedirectDestination(
          false,
          data.jurisdiction,
          i18n.language as Language,
          resourceMap,
          resourceKey,
        );

        // Before routing away, delete the cookie that stores the resource key
        if (hasCookie(CookieName.MappingKey)) {
          deleteCookie(CookieName.MappingKey, { domain: `${getEnvVars().NEXT_PUBLIC_RAIN_DOMAIN || ''}` });
        }
        location.href = destination;
      } catch (err) {
        setIsSubmitting(true);
      }
    } else {
      setError(t('validations.required', { fieldName: t('country-label') }));
    }
  };

  return (
    <Grid>
      <Body data-testid={`${testId}-body`} bottom>
        <FormFieldWrapper>
          <CountryLabel variant={EHeading.Default}>{t('country-label')}</CountryLabel>
          <CountrySelect
            name="country"
            lang={i18n.language as Language}
            countries={countries}
            value={selectedCountry}
            label={t('selectfield-country-label')}
            onChange={country => {
              if (country) setError(undefined);
              setSelectedCountry(country);
            }}
            noCountryFound={t('no-countries-found')}
            error={!!error}
            helperText={error}
          />
        </FormFieldWrapper>
        <CenteredDiv>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <Button type="submit" variant={'primary'} processing={isSubmitting} onClick={onSubmit}>
            {t('next')}
          </Button>
        </CenteredDiv>
      </Body>
    </Grid>
  );
};

export { CountrySelectRouter };
