import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Wrapper = styled.div(
  ({ theme }) => css`
    min-height: 100vh;
    height: 100%;
    width: 100%;
    position: relative;
    padding: ${theme.spaces.xxlarge} auto;
  `,
);

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1024px;
  position: relative;
  margin: 0 auto;
`;

export const HeaderWrapper = styled.div<{ isWebView: boolean }>(
  ({ theme, isWebView }) => css`
    box-sizing: border-box;
    margin: 0 auto 0;
    padding: ${theme.spaces.xxlarge};
    padding-bottom: ${theme.spaces.xxxlarge};
    display: block;
    width: 100%;
    text-align: center;

    .logo-link {
      z-index: 1;
    }

    ${isWebView &&
    css`
      padding: ${theme.spaces.large};

      .logo-link {
        display: none;
      }
    `}
  `,
);
