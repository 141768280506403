import React from 'react';
import { useTranslation } from 'next-i18next';

import { Button, Grid } from '@rainhq/ui-library';
import { SessionKeys } from '@rainhq/models';
import { createCookie } from '@rainhq/dom-storage';

import { Body } from 'library/layouts/SupportRouter/SupportRouter.styles';

import { getEnvVars } from 'services/vars';

export interface ISignInRouter {
  'data-testid'?: string;
  isSubmitting?: boolean;
}

const SignInRouter: React.FC<ISignInRouter> = ({ 'data-testid': testId = 'signIn', isSubmitting }) => {
  const { t } = useTranslation(['common']);

  const handleSignin = () => {
    // We need to pass the support route to get back to after login.
    // We also need to pass the domain to be able to delete the cookie later on.
    // You will need domain to delete a cookie that was created with that specifc domain.
    const returnRouteConfig = {
      route: getEnvVars().NEXT_PUBLIC_ROOT_URL,
      domain: getEnvVars().NEXT_PUBLIC_RAIN_DOMAIN,
    };

    createCookie(
      SessionKeys.NextRoute,
      JSON.stringify(returnRouteConfig),
      undefined,
      undefined,
      `.${getEnvVars().NEXT_PUBLIC_RAIN_DOMAIN}`,
    );
    location.href = `${getEnvVars().NEXT_PUBLIC_RAIN_URL}/signin`;
  };

  return (
    <Grid>
      <Body data-testid={`${testId}-button-col`}>
        <Button variant={'primary'} disabled={isSubmitting} onClick={handleSignin}>
          {t('sign-in')}
        </Button>
      </Body>
    </Grid>
  );
};

export { SignInRouter };
