import { Jurisdiction } from '@rainhq/models';

import { Language } from 'enums/language';
import { ResourceMap } from './contentful';
import { getEnvVars } from './vars';

const getSupportLoginURLByJurisdiction = (jurisdiction: Jurisdiction) => {
  switch (jurisdiction) {
    case Jurisdiction.Turkey:
      return getEnvVars().NEXT_PUBLIC_TR_FRESHDESK_LOGIN_URL || '';
    case Jurisdiction.UAE:
      return getEnvVars().NEXT_PUBLIC_AE_FRESHDESK_LOGIN_URL || '';
    default:
      return getEnvVars().NEXT_PUBLIC_BH_FRESHDESK_LOGIN_URL || '';
  }
};

const getSupportAuthenticationURLByJurisdiction = (jurisdiction: Jurisdiction) => {
  switch (jurisdiction) {
    case Jurisdiction.Turkey:
      return getEnvVars().NEXT_PUBLIC_TR_FRESHDESK_AUTH_REDIRECT_URL || '';
    case Jurisdiction.UAE:
      return getEnvVars().NEXT_PUBLIC_AE_FRESHDESK_AUTH_REDIRECT_URL || '';
    default:
      return getEnvVars().NEXT_PUBLIC_BH_FRESHDESK_AUTH_REDIRECT_URL || '';
  }
};

const getFreshDeskSupportURLByJurisdiction = (jurisdiction: Jurisdiction) => {
  let subDomain = getEnvVars().NEXT_PUBLIC_BH_FRESHDESK_ACCOUNT || '';

  switch (jurisdiction) {
    case Jurisdiction.Turkey:
      subDomain = getEnvVars().NEXT_PUBLIC_TR_FRESHDESK_ACCOUNT || '';
      break;
    case Jurisdiction.UAE:
      subDomain = getEnvVars().NEXT_PUBLIC_AE_FRESHDESK_ACCOUNT || '';
      break;
    default:
      subDomain = getEnvVars().NEXT_PUBLIC_BH_FRESHDESK_ACCOUNT || '';
      break;
  }
  return `https://${subDomain}.${getEnvVars().NEXT_PUBLIC_RAIN_DOMAIN || ''}`;
};

const getResourceIdFromResourceMap = (jurisdiction: Jurisdiction, resourceMap: ResourceMap) => {
  if (jurisdiction === Jurisdiction.BVI) return resourceMap.default?.toString();

  return resourceMap[jurisdiction]?.toString();
};

const getResourcePath = (resourceType: string, resourceId?: string) => {
  if (!resourceType || !resourceId) return '';

  switch (resourceType) {
    case 'categories':
      return `solutions/${resourceId}`;
    case 'sections':
      return `solutions/folders/${resourceId}`;
    case 'articles':
      return `solutions/articles/${resourceId}`;
  }
};

const getHelpCenterPath = (
  jurisdiction: Jurisdiction,
  locale?: Language,
  resourceMap?: ResourceMap | null,
  resourceKey?: string,
): string => {
  let helpCenterPath = '';

  // IF KEY IS "CONTACT", SET TO NEW REQUEST URL
  if (resourceKey === 'contact') {
    return (helpCenterPath += `${locale ? '/' + locale : ''}/support/tickets/new`);
  }

  if (resourceMap != null) {
    const resourceId = getResourceIdFromResourceMap(jurisdiction, resourceMap);
    const path = getResourcePath(resourceMap.resourceType, resourceId) || '';
    helpCenterPath += `${locale ? '/' + locale : ''}/support/${path}`;
  }

  return helpCenterPath;
};

export const computeSupportURL = (
  jurisdiction: Jurisdiction,
  language?: Language,
  resourceMap?: ResourceMap | null,
  resourceKey?: string,
) => {
  const freshdeskSupportUrl = getFreshDeskSupportURLByJurisdiction(jurisdiction);
  const supportPath = getHelpCenterPath(jurisdiction, language, resourceMap, resourceKey);

  return `${freshdeskSupportUrl}${supportPath}`;
};

export const getRedirectDestination = (
  requireAuth: boolean,
  jurisdiction: Jurisdiction,
  language?: Language,
  resourceMap?: ResourceMap | null,
  resourceKey?: string,
  ssoToken?: string,
  nonce?: string,
  state?: string,
) => {
  if (requireAuth) {
    if (!nonce || !state) return getSupportLoginURLByJurisdiction(jurisdiction);

    if (ssoToken) {
      return `${getSupportAuthenticationURLByJurisdiction(jurisdiction)}/implicit?state=${state}&id_token=${ssoToken}`;
    }
  }

  return computeSupportURL(jurisdiction, language, resourceMap, resourceKey);
};
