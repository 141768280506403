import { Global, css, useTheme } from '@emotion/react';
import { ITheme } from '@rainhq/ui-library';

const Type: React.FC = () => {
  const theme: ITheme = useTheme();

  return (
    <Global
      styles={css`
        html {
          font-size: 10px;

          &[lang='ar'] {
            body {
              // Language Specific Default System Font Override
              font-family: 'Naskh', 'Source Sans Pro', sans-serif !important;
              line-height: 1.2;
            }

            @media screen and (max-width: 700px) {
              font-size: 7px;
            }
          }
        }

        body {
          background-color: ${theme.colors.background};
          color: ${theme.colors.onBackground};
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 1.5rem;
          line-height: 1.2;
          -webkit-font-smoothing: antialiased;
        }

        select,
        input,
        button {
          color: inherit;
          font-family: 'Source Sans Pro', sans-serif;
        }

        a {
          color: ${theme.colors.secondary};
          text-decoration: none;
          transition: color 100ms;

          &:hover {
            color: ${theme.colors.secondaryLight};
            text-decoration: none;
          }
        }

        a.link-danger {
          color: ${theme.colors.primary};
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${theme.colors.onBackgroundVariant};
          font-weight: 400;
        }

        h1 {
          font-size: 3rem;

          @media screen and (max-width: 475px) {
            font-size: 2.5rem;
          }
        }

        h2 {
          font-size: 2.5rem;

          @media screen and (max-width: 475px) {
            font-size: 2rem;
          }
        }

        h3 {
          font-size: 1.8rem;

          @media screen and (max-width: 475px) {
            font-size: 1.5rem;
          }
        }

        h4 {
          font-size: 1.5rem;

          @media screen and (max-width: 475px) {
            font-size: 1.3rem;
          }
        }

        strong,
        b {
          font-size: inherit;
          font-weight: 600;
        }
      `}
    />
  );
};

const Reset: React.FC = () => {
  return (
    <Global
      styles={css`
        html {
          * {
            box-sizing: border-box;
            margin: 0;
          }
          .clearfix:before,
          .clearfix:after {
            content: '';
            display: table;
          }
          .clearfix:after {
            clear: both;
          }
        }
      `}
    />
  );
};

export const GlobalStyles: React.FC = () => {
  return (
    <>
      <Type />
      <Reset />
    </>
  );
};
